/* Desktop */
.div-nuestros-servicios.servicios{
    display:unset;
    position:absolute;
    width:100%;
    height:calc(100% - 179px); 
    z-index: 1;
    right:10vw;
    left:0px;
    top:120px;
    margin:auto;
    overflow: auto;
    scroll-behavior: smooth;
}
.nuestros-servicios.servicios{
    position:inherit;
    left:125px;
    width:180px;
    color: #FFF;
    text-align: left;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: Poppins;
    font-size: 3vw;   /*70px*/
    font-style: normal;
    font-weight: 600;
    line-height: 44px; /* 27.333% */
    letter-spacing: 1.5px;
    margin-bottom:60px;
}
.nuestros-servicios-cubrimos{
    position:inherit;
    top:55px;
    width:100%;
    color: #FFF;
    text-align: center;
    position:inherit;
    color: #FFF;
    text-align: center;
    text-shadow: 0px 8px 4px rgba(0, 0, 0, 0.25);
    font-family: 'MySoul';
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: 56px; /* 16.4% */
    letter-spacing: 2.5px;
    bottom:0px;
    margin:0px;
    z-index: 2;
}
.nuestros-servicios-administracion{
    position:inherit;
    top:100px;
    width:100%;
    color: #FFF;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: Poppins;
    font-size: 2vw;   /*70px*/
    font-style: normal;
    font-weight: 600;
    line-height: 44px; /* 27.333% */
    letter-spacing: 1.5px;
    margin-bottom:60px;
}
.nuestros-servicios-detalle.servicios{
    position:inherit;
    top:10px;
    color: #1F2C4E;
    font-family: Poppins;
    font-size: 1.5vw;/*30px;*/
    font-style: normal;
    text-align:justify;
    font-weight: 500;
    line-height: 44px; /* 121.818% */
    letter-spacing: 1.65px;
}

.div-servicios.servicios{
    position:absolute;
    display:flex;
    flex-wrap:wrap;
    row-gap: 10px;
    column-gap: 20px;
    top:187px;
    width:80%;
    left:0%;
    right:0%;
    margin:auto;
    height:100px;
}
.div-cuadro-servicios.servicios{
    display:flex;
    position:relative;
    flex-wrap: nowrap;
    width:350px;
    height:100%;
}
.imagen-servicio.servicios{
    width: 100px;
}
.div-letra-cuadro-servicios{
    width:250px;
    position:absolute;
   left:110px;
   height:fit-content;
   top:0px;
   bottom:0px;
  margin-top:auto;
  margin-bottom: auto;
}
.titulo-servicio.servicios{
    color: #FFF;
    text-align: left;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: Poppins;
    font-size: 20px;   /*70px*/
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 27.333% */
    letter-spacing: 1px;
}
.descripcion-servicio.servicios{
    color: #FFF;
    text-align: left;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: Poppins;
    font-size: 12px;   /*70px*/
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 27.333% */
    letter-spacing: 1.5px;
    margin-top: 14px;
}

.div-circuitos-izquierda.servicios{
    position:inherit;
    top:0px;
    width:100px;
    left: 16px;
}
.circuitos-izquierda.servicios{
    width:100%;
    height:100%;
}

 /*Responsive*/
 @media only screen 
and (max-device-height : 880px) 
and (orientation: landscape) {
    
}
@media only screen 
and (max-device-height : 794px) 
and (orientation: landscape) {
   
}
@media only screen 
and (max-device-height : 665px) 
and (orientation: landscape) {
   
}



@media only screen /* móviles en general portrait*/
and (orientation: portrait) {
  
    .div-nuestros-servicios.servicios{
        top:95px;
        height:calc(100% - 150px);
    }
    .nuestros-servicios.servicios{
        font-size: 20px;
    }
    .div-circuitos-izquierda.servicios{
        top:0px;
        right: 13px;
        width:95px;
    }
    .nuestros-servicios-cubrimos.servicios{
       font-size: 28px;
    }
    .nuestros-servicios-administracion.servicios{
        top:78px;
        font-size:15px;
    }
    .div-servicios.servicios{
        left:35px;
        width:70%;
        row-gap: 15px;
        top:130px;
    }
    .titulo-servicio.servicios{
        font-size: 15px;   /*70px*/
    }
    .descripcion-servicio.servicios{
        font-size: 9px;
    }
   
}
@media only screen /* Glaxy, Iphone Se y responsive*/
and (max-device-width : 375px) 
and (max-device-height : 667px) 
and (orientation: portrait) {
  
  
}
@media only screen /* horizontal Responsive - Iphone Se*/
and (max-device-width : 1081px) 
and (orientation: landscape) {
  
  
}
/* #Dispositivo = Iphone 6/7/8  (hasta el 2023)
#Resolucion = B/w 768px to 1024px */
@media only screen 
and (max-device-width : 414px) 
and (max-device-height : 736px) 
and (-webkit-device-pixel-ratio : 3 ) 
and (orientation : portrait) {

}
/* iPads (portrait) 
#Dispositivo = Tablets, Ipads (portrait)  (hasta el 2023)
#Resolucion = B/w 768px to 1024px */
@media only screen and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : portrait) {
    .div-nuestros-servicios.servicios{
        top:150px;
        height:calc(100% - 205px);
    }
    .nuestros-servicios.servicios{
        font-size: 35px;
    }
    .nuestros-servicios-cubrimos.servicios{
        font-size: 50px;
     }
     .nuestros-servicios-administracion.servicios{
         top:90px;
         font-size:30px;
     }
}
/* 
#Dispositivo = Ipad Mini
#Resolucion = 1024px */
@media only screen 
and (min-device-width : 1024px) 
and (max-device-width : 1024px) 
and (orientation : landscape) {
}
/* 
#Dispositivo = Ipad Mini
#Resolucion = 1024px */
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 768px) 
and (orientation : portrait) {
   
}
@media only screen /* Ipads Pro portrait 2024*/
and (min-device-width : 1025px) 
and (max-device-width : 1500px)
and (orientation: portrait) {
    .div-nuestros-servicios.servicios{
        top:150px;
        height:calc(100% - 179px);
    }
    .nuestros-servicios.servicios{
        font-size: 35px;
    }
    .nuestros-servicios-cubrimos.servicios{
        font-size: 50px;
     }
     .nuestros-servicios-administracion.servicios{
         top:90px;
         font-size:30px;
     }
}
/* iPads (portrait) 
#Dispositivo = Tablets, Ipads (vertical)  (hasta el 2023)*/
@media only screen /* Ipads landscape*/
and (min-device-width : 1025px) 
and (max-device-width : 1500px)
and (orientation: landscape) {
   
 }
/* Iphone 16 Iphone 12 pro y 12 pro max*/
@media only screen 
and (max-device-width: 390px) 
and (max-device-height: 844px)
and (max-resolution: 3dppx) 
and (min-width: 200px)
and (-webkit-device-pixel-ratio : 3 ) 
and (orientation: portrait) 
{ 
   
    
}
/* Termina Movil*/