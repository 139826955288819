/* Desktop */
.div-quienes-somos{
    display:none;
    position:absolute;
}
.div-quienes-somos.conocenos{
    display:unset;
    position:absolute;
    width:50vw;
    z-index: 1;
    right:0px;
    left:10vw;
    top:0px;
    bottom:0px;
    margin:auto;
   aspect-ratio: 8/3;
    max-height: 100vh;
  
}
.quienes-somos.conocenos{
    color: #FFF;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: Poppins;
    font-size: 3.5vw;   /*70px*/
    font-style: normal;
    font-weight: 600;
    line-height: 44px; /* 27.333% */
    letter-spacing: 1.5px;
    margin-bottom:60px;
   
}
.quienes-somos-detalle.conocenos{
    position:inherit;
    top:10px;
    color: #1F2C4E;
    font-family: Poppins;
    font-size: 1.5vw;/*30px;*/
    font-style: normal;
    text-align:justify;
    font-weight: 500;
    line-height: 44px; /* 121.818% */
    letter-spacing: 1.65px;
}
.div-circuitos-derecha.conocenos{
    position:inherit;
    top:0px;
    width:158px;
    right: -118px;
}
.circuitos-derecha.conocenos{
    width:100%;
    height:100%;
}
 /*Responsive*/
 @media only screen 
and (max-device-height : 880px) 
and (orientation: landscape) {
    .div-quienes-somos.conocenos{
        width:45vw;
        max-height:90vh; /*550px*/
        transition: all .5s ease;
    }
    .quienes-somos.conocenos{
        font-size: 4vw;   /*70px*/
        margin-bottom:50px;
        transition: all .5s ease;
    }
    .quienes-somos-detalle.conocenos{
        font-size: 1.5vw;/*30px;*/
        transition: all .5s ease;
    }
    .div-circuitos-derecha.conocenos{
        transition: all .5s ease;
        width:150px;
    }
}

@media only screen 
and (max-device-height : 794px) 
and (orientation: landscape) {
    .div-quienes-somos.conocenos{
        width:40vw;
        max-height:90vh; /*550px*/
        margin-bottom:155px;
        aspect-ratio: 10/6;
        transition: all .5s ease;
    }
    .quienes-somos.conocenos{
        font-size: 2vw;   /*70px*/
        transition: all .5s ease;
    }
    .quienes-somos-detalle.conocenos{
        font-size: 4vh;/*30px;*/
        transition: all .5s ease;
    }
    .div-circuitos-derecha.conocenos{
        width:130px;
    }
}
@media only screen 
and (max-device-height : 665px) 
and (orientation: landscape) {
    .div-quienes-somos.conocenos{
        width:50vw;
        max-height:90vh; /*550px*/
        margin-top:100px;
        aspect-ratio: 13/6;
        transition: all .5s ease;
       overflow:scroll;
    }
}

@media only screen /* móviles en general portrait*/
and (orientation: portrait) {
    .div-quienes-somos {
        width: 20vw;
        margin-left:calc(100vw - 60vw) ;
        top:2.5vh;
        height:58vh;
    }
    .div-quienes-somos.conocenos{
        width:100vw;
        right:0px;
        left:0px;
        top:5vh;
        bottom:0px;
        margin:auto;
        aspect-ratio: 8/3;
    }
    .quienes-somos.conocenos{
        font-size: 8vw;   /*70px*/
    }
    .div-quienes-somos-detalle.conocenos {
        position:absolute;
        top:55px;
        width:65%;
        height:53vh;
        left:57px;
        margin-bottom: 10px;
    }
    .quienes-somos-detalle.conocenos{
        top:unset;
        color: #1F2C4E;
        font-size: 3.5vw;/*30px;*/
        line-height: 20px; /* 121.818% */
        letter-spacing: 0.5px;
    }
    .div-circuitos-derecha.conocenos{
        top:unset;
        bottom:0px;
        right: 28px;
        width:90px;
    }

}
@media only screen /* Glaxy, Iphone Se y responsive*/
and (max-device-width : 375px) 
and (max-device-height : 667px) 
and (orientation: portrait) {
    .div-quienes-somos {
        top:3vh;
    }
    .div-quienes-somos.conocenos {
       height:70vh;
    }
    .div-circuitos-derecha.conocenos{
        bottom:0vh;
        right:20px;
        width:100px;
    }
  
}
@media only screen /* horizontal Responsive - Iphone Se*/
and (max-device-width : 1081px) 
and (orientation: landscape) {
    .div-quienes-somos {
        top:3vh;
        overflow:scroll;
    }
  
}
/* #Dispositivo = Iphone 6/7/8  (hasta el 2023)
#Resolucion = B/w 768px to 1024px */
@media only screen 
and (max-device-width : 414px) 
and (max-device-height : 736px) 
and (-webkit-device-pixel-ratio : 3 ) 
and (orientation : portrait) {

}
/* iPads (portrait) 
#Dispositivo = Tablets, Ipads (vertical)  (hasta el 2023)
#Resolucion = B/w 768px to 1024px */
@media only screen and (min-device-width : 768px) 
and (max-device-width : 1088px) 
and (orientation : portrait) {
    .div-quienes-somos.conocenos{
        height:68vh;
    }
    .div-quienes-somos-detalle.conocenos {
        position:absolute;
        top:55px;
        width:80%;
        height:53vh;
        left:40px;
        margin-bottom: 10px;
    }
    .quienes-somos-detalle.conocenos{
        position:inherit;
        top:10px;
        color: #1F2C4E;
        font-family: Poppins;
        font-size: 2.5vw;/*30px;*/
        font-style: normal;
        font-weight: 500;
        line-height: 44px; /* 121.818% */
        letter-spacing: 1.65px;
    }
    .div-circuitos-derecha.conocenos{
        top:unset;
        bottom:0px;
        right: 0px;
        width:140px;
    }
}
/* 
#Dispositivo = Ipad Mini
#Resolucion = 1024px */
@media only screen 
and (min-device-width : 1024px) 
and (max-device-width : 1024px) 
and (orientation : landscape) {
}
/* 
#Dispositivo = Ipad Mini
#Resolucion = 1024px */
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 768px) 
and (orientation : portrait) {
    .div-quienes-somos.conocenos{
        height:57vh;
    }
    .div-circuitos-derecha.conocenos{
        top:unset;
        bottom:0px;
        right: 0px;
        width:140px;
       
    }
}
@media only screen /* Ipads Pro portrait 2024*/
and (min-device-width : 1025px) 
and (max-device-width : 1500px)
and (orientation: portrait) {
    .div-quienes-somos {
        top:1.6vh;
       
    }
    .div-quienes-somos.conocenos{
        height:40vh;
    }
    .div-quienes-somos-detalle.conocenos {
        position:absolute;
        top:55px;
        width:85%;
        height:53vh;
        left:40px;
        margin-bottom: 10px;
    }
    .quienes-somos-detalle.conocenos{
        position:inherit;
        top:10px;
        color: #1F2C4E;
        font-family: Poppins;
        font-size: 2.5vw;/*30px;*/
        font-style: normal;
        font-weight: 500;
        line-height: 44px; /* 121.818% */
        letter-spacing: 1.65px;
    }
    .div-circuitos-derecha.conocenos{
        top:unset;
        bottom:0px;
        right: 0px;
        width:110px;
    }
}
/* iPads (portrait) 
#Dispositivo = Tablets, Ipads (vertical)  (hasta el 2023)*/
@media only screen /* Ipads landscape*/
and (min-device-width : 1025px) 
and (max-device-width : 1500px)
and (orientation: landscape) {
    .div-quienes-somos {
        top:1.6vh;
    
    }
 }
/* Iphone 16 Iphone 12 pro y 12 pro max*/
@media only screen 
and (max-device-width: 390px) 
and (max-device-height: 844px)
and (max-resolution: 3dppx) 
and (min-width: 200px)
and (-webkit-device-pixel-ratio : 3 ) 
and (orientation: portrait) 
{ 
    .div-quienes-somos.conocenos{
        height:58vh;
    }
    .div-circuitos-derecha.conocenos{
        top:unset;
        bottom:-30px;
        right: 13px;
        width:95px;
    }
    
}

/* Iphone 16 Iphone 12 pro y 12 pro max*/
@media only screen 
and (max-resolution: 3dppx) 
and (-webkit-device-pixel-ratio : 3 ) 
and (orientation: landscape) 
{ 
    .div-quienes-somos.conocenos{
        max-height: unset;
        height:50%;
    }

}



/* Termina Movil*/