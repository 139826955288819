.div-leer-mas{
    display:none;
    position:absolute;
    left:0px;
    margin:auto;
    bottom:10vh;
    right:0px;
    width: 180px; 
    height: 30px;
    background:transparent;
 }
 .div-leer-mas.conocenos{
   bottom:10vh;
 }
.leer-mas{
    position:inherit;
    margin:auto;
    width: 100%; 
    height: 100%;
    color: #FFF;
    text-align: center;
    text-shadow: -3px 4px 4px rgba(50, 90, 164, 0.50);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 85.417% */
    letter-spacing: -0.48px;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 16px;
    border-color:#95badb;
 }
 @media only screen /* móviles en general portrait*/
and (orientation: portrait) {
    .div-leer-mas{
        bottom:10vh;
        left:0px;
        right:100%;
        width: 100%;
        margin:0px;
        padding:0px;
     }
     .div-leer-mas.conocenos{
      bottom:10vh;
      left:0px;
      right:100%;
      width: 100%;
      margin:0px;
      padding:0px;
      height: 20px;
   }
     .leer-mas{
        top:0px;
        bottom:0px;
        left:0px;
        right:0px;
        width: 30%;
        height:100%; 
     }

     .leer-mas.conocenos{
      top:0px;
      bottom:0px;
      left:0px;
      right:0px;
      width: 30%;
      height:100%; 
      font-size: 15px;
   }
}
@media only screen /* Glaxy, Iphone Se y responsive*/
and (max-device-width : 375px) 
and (max-device-height : 667px) 
and (orientation: portrait) {
    .div-leer-mas{
        bottom: 10vh;
     }
     .div-leer-mas.conocenos{
      bottom:10vh;
     }
}
/* #Dispositivo = Iphone 6/7/8  (hasta el 2023)
#Resolucion = B/w 768px to 1024px */
@media only screen 
and (max-device-width : 414px) 
and (max-device-height : 896px) 
and (-webkit-device-pixel-ratio : 3 ) 
and (orientation : portrait) {
     .div-leer-mas{
        top:unset;
        bottom:10vh;
     }
     .div-leer-mas.conocenos{
      bottom:10vh;
     }
}
/* iPads (portrait) 
#Dispositivo = Tablets, Ipads (vertical)  (hasta el 2023)
#Resolucion = B/w 768px to 1024px */
@media only screen and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : portrait) {
     .div-leer-mas{
        top:unset;
        bottom:10vh;
     }
     .div-leer-mas.conocenos{
      bottom:10vh;
      height: 50px;
   }
     .leer-mas.conocenos{
      top:0px;
      bottom:0px;
      left:0px;
      right:0px;
      width: 30%;
      height:100%; 
      font-size: 30px;
   }
}
/* 
#Dispositivo = Ipad Mini
#Resolucion = 1024px */
@media only screen 
and (min-device-width : 1024px) 
and (max-device-width : 1024px) 
and (orientation : landscape) {
    .div-leer-mas{
       /* top:min(550px,80vh);*/
       top:10vh;
     }
}
/* 
#Dispositivo = Ipad Mini
#Resolucion = 1024px */
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 768px) 
and (orientation : portrait) {
     .div-leer-mas{
        top:unset;
        bottom: 10vh;
     }
     .div-leer-mas.conocenos{
      bottom:10vh;
   }
}
@media only screen /* Ipads Pro portrait 2024*/
and (min-device-width : 1025px) 
and (max-device-width : 1500px)
and (orientation: portrait) {
    .div-leer-mas{
        top:unset;
        bottom:10vh;
     }
     .div-leer-mas.conocenos{
      bottom:10vh;
      height: 50px;
   }
     .leer-mas.conocenos{
      top:0px;
      bottom:0px;
      left:0px;
      right:0px;
      width: 30%;
      height:100%; 
      font-size: 30px;
   }
}
/* iPads (portrait) 
#Dispositivo = Tablets, Ipads (vertical)  (hasta el 2023)*/
@media only screen /* Ipads landscape*/
and (min-device-width : 1025px) 
and (max-device-width : 1500px)
and (orientation: landscape) {
    .div-leer-mas{
       bottom:10vh;
     }
}
/* Iphone 16 Iphone 12 pro */
@media only screen 
and (max-device-width: 393px) 
and (max-resolution: 3dppx) 
and (min-width: 200px)
and (-webkit-device-pixel-ratio : 3 ) 
and (orientation: portrait) 
{ 
     .div-leer-mas{
        bottom:10vh;
        width:100vw;
     }
     .leer-mas{
       width: 50%;
     }
     .div-leer-mas.conocenos{
      bottom:10vh;
   }
}