/* Desktop */
.div-contactenos{
    display:none;
    position:absolute;
}
.div-contactenos.contacto{
    display:unset;
    position:absolute;
    width:100%;
    height:100%;
    z-index: 1;
    right:0px;
    left:0px;
    top:0px;
    bottom:0px;
    margin:auto;
}
.div-contactos.contacto{
   position:absolute;
   z-index: 1;
   width:30%;
   height:70%;
   left:0px;
   right:0px;
   top:0px;
   bottom:0px;
   margin:auto;
}
.img-contactos.contacto{
    position:absolute;
    z-index: 1;
    width:100%;
    left:0px;
   right:0px;
   top:0px;
   bottom:0px;
   margin:auto;
}
.div-circuito-azul.contacto{
    position:absolute;
    width:500px;
    height:700px;
    bottom:40px;
    right:0px;
    margin:0px;
}
.img-circuito-azul{
    width:100%;
    height:100%;
}
 /*Responsive*/
 @media only screen 
and (max-device-height : 880px) 
and (orientation: landscape) {
    .div-circuito-azul.contacto{
        bottom:0px;
    }
 
}
@media only screen 
and (max-device-height : 794px) 
and (orientation: landscape) {
    .div-circuito-azul.contacto{
        bottom:0px;
    }
   
}
@media only screen 
and (max-device-height : 665px) 
and (orientation: landscape) {
    .div-circuito-azul.contacto{
        bottom:0px;
    }
   
}

@media only screen /* móviles en general portrait*/
and (orientation: portrait) {
    .div-contactos.contacto{
        position:absolute;
        z-index: 1;
        width:60%;
        height:70%;
        left:0px;
        right:0px;
        top:0px;
        bottom:0px;
        margin:auto;
     }
     .div-circuito-azul.contacto{
        right:0px;
        margin:0px;
        height:500px;
        width:230px;
    }
}
@media only screen /* Glaxy, Iphone Se y responsive*/
and (max-device-width : 375px) 
and (max-device-height : 667px) 
and (orientation: portrait) {
 
}
@media only screen /* horizontal Responsive - Iphone Se*/
and (max-device-width : 1081px) 
and (orientation: landscape) {
 
}
/* #Dispositivo = Iphone 6/7/8  (hasta el 2023)
#Resolucion = B/w 768px to 1024px */
@media only screen 
and (max-device-width : 414px) 
and (max-device-height : 736px) 
and (-webkit-device-pixel-ratio : 3 ) 
and (orientation : portrait) {

}
/* iPads (portrait) 
#Dispositivo = Tablets, Ipads (vertical)  (hasta el 2023)
#Resolucion = B/w 768px to 1024px */
@media only screen and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : portrait) {

}
/* 
#Dispositivo = Ipad Mini
#Resolucion = 1024px */
@media only screen 
and (min-device-width : 1024px) 
and (max-device-width : 1024px) 
and (orientation : landscape) {
    .div-circuito-azul.contacto{
        bottom:0px;
    }
}
/* 
#Dispositivo = Ipad Mini
#Resolucion = 1024px */
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 768px) 
and (orientation : portrait) {

}
@media only screen /* Ipads Pro portrait 2024*/
and (min-device-width : 1025px) 
and (max-device-width : 1500px)
and (orientation: portrait) {
    .div-circuito-azul.contacto{
        right:0px;
        margin:0px;
        height:900px;
        width:330px;
    }
  

}
/* iPads (portrait) 
#Dispositivo = Tablets, Ipads (vertical)  (hasta el 2023)*/
@media only screen /* Ipads landscape*/
and (min-device-width : 1025px) 
and (max-device-width : 1500px)
and (orientation: landscape) {

 }
/* Iphone 16 Iphone 12 pro y 12 pro max*/
@media only screen 
and (max-device-width: 390px) 
and (max-device-height: 844px)
and (max-resolution: 3dppx) 
and (min-width: 200px)
and (-webkit-device-pixel-ratio : 3 ) 
and (orientation: portrait) 
{ 


}
/* Iphone 16 Iphone 12 pro y 12 pro max*/
@media only screen 
and (max-resolution: 3dppx) 
and (-webkit-device-pixel-ratio : 3 ) 
and (orientation: landscape) 
{ 
    .div-circuito-azul.contacto{
        bottom:0px;
        width:200px;
        right:60px;
    
    }
    .div-contactos.contacto{
        width:25%;
        bottom:unset;
    }
    .div-contactenos.contacto{
  
        width:50%;
    }

}
/* Termina Movil*/