.menu-div{
    width:100vw;
    position:absolute;
    display:flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items:center;
    z-index: 3;
}
.menu-button{
    display:none;
    cursor:pointer;
}
.menu-hamburguesa{
    width:2em;
    height:2em;
    filter: drop-shadow(0px 4px 4px #1F2C4E);
}
.menu-hamburguesa.servicio{
    width:2em;
    height:2em;
    filter: drop-shadow(0px 4px 4px #1F2C4E);
}
.menu-nav{
    position:inherit;
    left:12vw;
    top:40px;
    width:60vw;
    overflow:hidden;
}
.menu-ul{
    display:flex;
    flex-flow: row nowrap;
    justify-content:flex-start;
    align-items:center;
    list-style: none;
    overflow:scroll;
}
.menu-li{
    margin : 0 4vw;
    color: #FFF;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 41px; /* 85.417% */
    letter-spacing: 0.48px; 
}
.menu-a{
    text-decoration: none;
    color: #FFF;
}
.menu-a.inicio{
    display:none;
}
.menu-a:hover{
    text-decoration: none;
    color: #325AA4;
}
@media screen  and (max-width:1556px) {
    .menu-li{
        margin : 0 2vw;
    }  
}
@media screen  and (orientation:portrait) {
   .menu-div{
    right:0px;
   }
   .menu-div.conocenos{
    display:unset;
    right:15px;
    }
    .menu-button{
        display:flex;
        position:absolute;
        right:25px;
        top:65px;
        background:transparent;
        border:unset;
    }
    .menu-button.servicios{
        right:40px;
    }
    .menu-nav{
        width:100px;
        right:25px;
        top:105px;
        left:unset;
        display:flex;
        flex-flow:column nowrap;
        height:0vh;
        overflow:unset;
        opacity:0;
        transition: all .5s ease;
        padding:0px;
        background: rgba(255, 255, 255, 0.90);
        filter: drop-shadow(10px 11px 20.3px rgba(29, 43, 78, 0.20));
        border-radius: 10px;
    }
    .menu-nav:after{
        border-left: solid transparent 10px;
        border-right: solid transparent 10px;
        border-bottom: solid rgba(255, 255, 255, 0.90) 10px;
        top: -09px;
        content: " ";
        height: 0;
        right: 9px;
        position: absolute;
        width: 0; 
    }
    .menu-nav.isActive {
        height: 270px;
        opacity: 0.9;
        width: 200px;
       }
    .menu-nav.isActive.conocenos {
        height: 320px;
        opacity: 0.9;
        width: 200px;
    }
    .menu-ul{
        flex-direction: column;
        padding: 0px;
        align-items:center;
        overflow:unset;
    }
    .menu-li{
        width:100%;
        margin: .5em 0;
    }
    .menu-a{
        width:100%;
        text-decoration: none;
        color: #425FA1;
        text-align: center;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: Poppins;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.55px;
    }
  
    .menu-a:hover{
        text-decoration: none;
        color: #FFF;
        text-align: center;
        text-shadow: 0px 4px 4px #000;
        font-family: Poppins;
        font-style: normal;
        font-weight: 700;
        line-height: 41px; /* 64.063% */
        letter-spacing: 0.64px;
    }
    .menu-li:hover{
        background-color: #1F2C4E;
        border-radius: 10px;
    }
}
@media only screen 
and (max-resolution: 3dppx) 
and (-webkit-device-pixel-ratio : 3 ) 
and (orientation: landscape) 
{ 
    .menu-nav.conocenos{
        display:none;
    }

}