/* Desktop */
.base {
    position:absolute;
    width:100%;
    height:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    place-items: center;
    background: linear-gradient(
                                45deg, #325AA4 0%, #436CB0 11%,
                                 #759DCD 34% , #9AC3E1 50%, #95BDDB 55%, 
                                 #87ACCB 63%, #7192B2 70%, #567193 79%, 
                                 #9ac3e1 88%, #1F2C4E 97%
                                 );
}
.base.contacto{
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, #1D2C53 100%) !important;
    opacity: 0.3;
}
.div-fondo{
    position:inherit;
    top:0px;
    bottom:0px;
    left:0px;
    right:0px;
    width: 100%;
    height:100%; 
    margin:0px;
    padding:0px;
}
.div-fondo.contacto{
    display: none;
}
.fondo{
    position:inherit;
    width: 100%;
    height:100%; 
 }
.linea-gris{
    position: absolute;
    top: 28px;
    width: 100%;
    height: 3px;
    background: rgba(0, 0, 0, 0.20);
    z-index: 1;
}
.div-flag-mexico{
    position:absolute;
    top:0.5vh;
    right:10px;
    width:100px;
    height:20px;
    z-index: 1;
}
.flag-mexico{
    position:inherit;
    top:3px;
    width:24px;
}
.div-lenguaje{
    position:inherit;
    top:0px;
    width: 24px;
    height: 10px;
    flex-shrink: 0;
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size:12px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.72px;
    right:calc(100% - 51px);
}
.flecha-abajo{
    position:inherit;
    top:7px;
    right:0px;
    width:12px;
}
.infomununo{
    width: 300px; 
    height: 46px; 
    text-align: center; 
    color: white; 
    background-color: transparent;
    font-size: 28px; 
    line-height: 22px; 
    word-wrap: break-word;
    bottom: 0vh;
    position :absolute;
    margin-left:auto;
    margin-right:auto;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: normal;
    z-index: 1;
    left:0px;
    right:0px;
    text-decoration:none;
}
.infomununo.contacto {
    display:none;
}
 /*Comienza Movil*/
@media only screen /* móviles en general portrait*/
and (orientation: portrait) {
     .infomununo {
        font-size:20px;
        width: 100vw;
    }
    .div-flag-mexico {
        width:93px;
    }
    .flag-mexico{
        top:1px;
        width:20px;
    }
    .div-lenguaje{
        font-size:11px;
        line-height:14px;
        right:calc(100% - 51px);
    }
    .flecha-abajo{
        top:4px;
    }
    .linea-gris{
        top: 25px;
    }
    .infomununo.servicios {
        top:unset;
        bottom:0px;
     }
   
}
@media only screen /* Glaxy, Iphone Se y responsive*/
and (max-device-width : 375px) 
and (orientation: portrait) {
}
@media only screen /* horizontal Responsive - Iphone Se*/
and (max-device-width : 1081px) 
and (orientation: landscape) {
}
/* #Dispositivo = Iphone 6/7/8  (hasta el 2023)
#Resolucion = B/w 768px to 1024px */
@media only screen 
and (max-device-width : 414px) 
and (max-device-height : 736px) 
and (-webkit-device-pixel-ratio : 3 ) 
and (orientation : portrait) {
}
/* iPads (portrait) 
#Dispositivo = Tablets, Ipads (vertical)  (hasta el 2023)
#Resolucion = B/w 768px to 1024px */
@media only screen and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : portrait) {
}
/* 
#Dispositivo = Ipad Mini
#Resolucion = 1024px */
@media only screen 
and (min-device-width : 1024px) 
and (max-device-width : 1024px) 
and (orientation : landscape) {
}
/* 
#Dispositivo = Ipad Mini
#Resolucion = 1024px */
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 768px) 
and (orientation : portrait) {
}
@media only screen /* Ipads Pro portrait 2024*/
and (min-device-width : 1025px) 
and (max-device-width : 1500px)
and (orientation: portrait) {
   
}
/* iPads (portrait) 
#Dispositivo = Tablets, Ipads (vertical)  (hasta el 2023)*/
@media only screen /* Ipads landscape*/
and (min-device-width : 1025px) 
and (max-device-width : 1500px)
and (orientation: landscape) {
  
}
/* Iphone 16 Iphone 12 pro */
@media only screen 
and (max-device-width: 393px) 
and (max-resolution: 3dppx) 
and (min-width: 200px)
and (-webkit-device-pixel-ratio : 3 ) 
and (orientation: portrait) 
{ 
}
/* Termina Movil*/