.search-bar-div {
    display:none !important;
    position:absolute;
    top:65px;
    padding-right: 12px;
    border-radius: 20px;
    border: 1px solid #ccc;
    width: 220px;
    height:25px;
    display: flex;
    justify-content: start;
    gap: 0.5em;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Add this line for box shadow;*/
    right:80px;
    background:linear-gradient(rgba(255, 255, 255, 0.10),rgba(255, 255, 255, 0.10));
   }
.search-bar-div.conocenos{
    display:inherit;
    right:unset;
    width:400px;
    margin:auto;
    right:0px;
    left:0px;
   }   
.search-bar-input{
    display:inherit;
    width: 95%;
    border: none;
    z-index:  1;
    background: transparent;
    color:white;
    outline: none;
    font-family: Poppins;
 }
.search-bar-lupa{
    display:inherit;
    position:inherit;
    right:3px;
    color: white;
    height:14px;
    width: 14px;
}
@media screen  and (max-width:1119px) {
    .search-bar-div {
      left:30px;
      background:transparent;
      box-shadow:unset;
      width:50px;
      height:50px;
      border:unset;
    }
    .search-bar-input{
        display:none;
    }
    .search-bar-lupa{
        width:30px;
        height:30px;
        left:0px;
    }
}
@media only screen /* móviles en general portrait*/
and (orientation: portrait) {
    .search-bar-div.conocenos{
        right:unset;
        width:100%;
        margin:auto;
        right:0px;
        left:0px;
        padding: 0px;
       }  
    .search-bar-lupa.conocenos{
        width:30px;
        height:30px;
        left:15px;
    }
}