/* Desktop */
.div-logo-mununo{
    position:absolute;
    top:15px;
    width: 150px; 
    left:2vw;
    z-index: 1;
}
.div-logo-mununo.contacto{
    display:none;
}
.div-logo-mununo.conocenos{
    position:absolute;
    top:0px;
    bottom:0px;
    margin-top:auto;
    margin-bottom: auto;
    width: 25vw; 
    left:2vw;
    height:95%;
    z-index: 1;
}
.logo-mununo{
    width:100%;
    height:100%;
    top:0px;
    bottom:0px;
}
 /*Comienza Movil*/
@media only screen /* móviles en general portrait*/
and (orientation: portrait) {
    .div-logo-mununo {
        width: 20vw;
        margin-left:calc(100vw - 60vw) ;
        top:2.5vh;
    }
        .div-logo-mununo.conocenos {
            width:75vw;
            left:0%;
            right:0%;
            margin:auto;
        }
        .logo-mununo.conocenos{
            top:10px;
            bottom:unset;
            height:20vh;
            width: 100%;
        }
}
@media only screen /* Glaxy, Iphone Se y responsive*/
and (max-device-width : 375px) 
and (orientation: portrait) {
    .div-logo-mununo {
        top:3vh;
    }
    .div-logo-mununo.conocenos {
        width:75vw;
        left:0%;
        right:0%;
        margin:auto;
    }
    .logo-mununo.conocenos{
        top:10px;
        bottom:unset;
        height:20vh;
        width: 100%;
    }
}
@media only screen /* horizontal Responsive - Iphone Se*/
and (max-device-width : 1081px) 
and (orientation: landscape) {
    .div-logo-mununo {
        top:3vh;
    }
    .div-logo-mununo.conocenos {
        top:3vh;
    }

}
/* #Dispositivo = Iphone 6/7/8  (hasta el 2023)
#Resolucion = B/w 768px to 1024px */
@media only screen 
and (max-device-width : 414px) 
and (max-device-height : 736px) 
and (-webkit-device-pixel-ratio : 3 ) 
and (orientation : portrait) {
    .div-logo-mununo.conocenos {
        width:75vw;
        left:0%;
        right:0%;
        margin:auto;
    }
    .logo-mununo.conocenos{
        top:10px;
        bottom:unset;
        height:20vh;
        width: 100%;
    }
}
/* iPads (portrait) 
#Dispositivo = Tablets, Ipads (vertical)  (hasta el 2023)
#Resolucion = B/w 768px to 1024px */
@media only screen and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : portrait) {
    .div-logo-mununo.conocenos {
        width:75vw;
        left:0%;
        right:0%;
        margin:auto;
    }
    .logo-mununo.conocenos{
        top:10px;
        bottom:unset;
        height:20vh;
        width: 100%;
    }
}
/* 
#Dispositivo = Ipad Mini
#Resolucion = 1024px */
@media only screen 
and (min-device-width : 1024px) 
and (max-device-width : 1024px) 
and (orientation : landscape) {
    
}
/* 
#Dispositivo = Ipad Mini
#Resolucion = 1024px */
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 768px) 
and (orientation : portrait) {
    .div-logo-mununo.conocenos {
        width:75vw;
        left:0%;
        right:0%;
        margin:auto;
    }
    .logo-mununo.conocenos{
        top:10px;
        bottom:unset;
        height:20vh;
        width: 100%;
    }
}
@media only screen /* Ipads Pro portrait 2024*/
and (min-device-width : 1025px) 
and (max-device-width : 1500px)
and (orientation: portrait) {
    .div-logo-mununo {
        top:1.6vh;
    }
    .div-logo-mununo.conocenos {
        width:75vw;
        left:0%;
        right:0%;
        margin:auto;
    }
    .logo-mununo.conocenos{
        top:10px;
        bottom:unset;
        height:20vh;
        width: 100%;
    }
}
/* iPads (portrait) 
#Dispositivo = Tablets, Ipads (vertical)  (hasta el 2023)*/
@media only screen /* Ipads landscape*/
and (min-device-width : 1025px) 
and (max-device-width : 1500px)
and (orientation: landscape) {
    .div-logo-mununo {
        top:1.6vh;
    }
    .div-logo-mununo.conocenos {
        top:1.6vh;
    }
}
/* Iphone 16 Iphone 12 pro */
@media only screen 
and (max-device-width: 393px) 
and (max-resolution: 3dppx) 
and (min-width: 200px)
and (-webkit-device-pixel-ratio : 3 ) 
and (orientation: portrait) 
{ 
    .div-logo-mununo.conocenos {
        width:75vw;
        left:0%;
        right:0%;
        margin:auto;
    }
    .logo-mununo.conocenos{
        top:10px;
        bottom:unset;
        height:20vh;
        width: 100%;
    }
}
/* Termina Movil*/