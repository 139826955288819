/* Desktop */
.div-logo-mununo-blanco{
    display:none;
}
.div-logo-mununo-blanco.conocenos{
    position:absolute;
    display:unset;
    top:65px;
    width: 10vw; 
    left:2vw;
    z-index: 1;
}
 /*Comienza Movil*/
@media only screen /* móviles en general portrait*/
and (orientation: portrait) {
    .div-logo-mununo-blanco.conocenos {
        display:none;
    }
}
@media only screen /* Glaxy, Iphone Se y responsive*/
and (max-device-width : 375px) 
and (orientation: portrait) {
  
}
@media only screen /* horizontal Responsive - Iphone Se*/
and (max-device-width : 1081px) 
and (orientation: landscape) {
  
}
/* #Dispositivo = Iphone 6/7/8  (hasta el 2023)
#Resolucion = B/w 768px to 1024px */
@media only screen 
and (max-device-width : 414px) 
and (max-device-height : 736px) 
and (-webkit-device-pixel-ratio : 3 ) 
and (orientation : portrait) {
}
/* iPads (portrait) 
#Dispositivo = Tablets, Ipads (vertical)  (hasta el 2023)
#Resolucion = B/w 768px to 1024px */
@media only screen and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : portrait) {
}
/* 
#Dispositivo = Ipad Mini
#Resolucion = 1024px */
@media only screen 
and (min-device-width : 1024px) 
and (max-device-width : 1024px) 
and (orientation : landscape) {
}
/* 
#Dispositivo = Ipad Mini
#Resolucion = 1024px */
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 768px) 
and (orientation : portrait) {
}
@media only screen /* Ipads Pro portrait 2024*/
and (min-device-width : 1025px) 
and (max-device-width : 1500px)
and (orientation: portrait) {
   
}
/* iPads (portrait) 
#Dispositivo = Tablets, Ipads (vertical)  (hasta el 2023)*/
@media only screen /* Ipads landscape*/
and (min-device-width : 1025px) 
and (max-device-width : 1500px)
and (orientation: landscape) {
   
}
/* Iphone 16 Iphone 12 pro */
@media only screen 
and (max-device-width: 393px) 
and (max-resolution: 3dppx) 
and (min-width: 200px)
and (-webkit-device-pixel-ratio : 3 ) 
and (orientation: portrait) 
{ 
}
/* Termina Movil*/