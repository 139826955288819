/* Desktop */
.div-innova-techno{
    position:absolute;
    width: 800px;;
    top:43vh;
    left:0px;
    height:100px;
    color: #243B6C;
    text-align: center;
    text-shadow: 0px 8px 4px rgba(0, 0, 0, 0.25);
    font-family: Poppins;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 41px; /* 32.031% */
    letter-spacing: 1.28px;
    z-index: 1;
 }
.innova-techno{
    position:inherit;
    top:0px;
    left:20px;
    color: #243B6C;
    text-align: center;
    text-shadow: 0px 8px 4px rgba(0, 0, 0, 0.25);
    font-family: Poppins;
    font-size: inherit;
    font-style: normal;
    font-weight: 700;
    line-height: 41px; /* 32.031% */
    letter-spacing: 1.28px;
    z-index: 1;
    margin:0px;
 }
 .con{
    position:inherit;
    bottom:0px;
    left:110px;
    color: #243B6C;
    text-align: center;
    text-shadow: 0px 8px 4px rgba(0, 0, 0, 0.25);
    font-family: Poppins;
    font-size: inherit;
    font-style: normal;
    font-weight: 700;
    line-height: 41px; /* 32.031% */
    letter-spacing: 1.28px;
    z-index: 1;
    margin:0px;
 }
.alma{
    position:inherit;
    color: #FFF;
    text-align: center;
    text-shadow: 0px 8px 4px rgba(0, 0, 0, 0.25);
    font-family: 'MySoul';
    font-size: 90px;
    font-style: normal;
    font-weight: 400;
    line-height: 41px; /* 16.4% */
    letter-spacing: 2.5px;
    bottom:0px;
    left:225px;
    margin:0px;
    z-index: 2;
 }
 /*Comienza Movil*/
@media only screen /* móviles en general portrait*/
and (orientation: portrait) {
    .div-innova-techno{
        height:74px;
        top:480px;
        margin-left:auto !important;
        margin-right:auto !important;
        padding:0px;
        max-width: 345px;
        right:0px;
        font-size: 25px;
     }
     .innova-techno{
        left:0px;
     }
     .con{
        left:35px;
        right:0px;
     }
     .alma{
        font-size: 50px;
        left:103px;
        letter-spacing: 1.0px;
     }
}
@media only screen /* Glaxy, Iphone Se y responsive*/
and (max-device-width : 375px) 
and (orientation: portrait) {
    .div-innova-techno{
        height:19vw;
        top:430px;
        max-width: 90vw;
        font-size: 5vw;
     }
     .con{
        left:0px;
     }
     .alma{
        font-size: 9vw;
        left:25vw;
     }
}
@media only screen /* horizontal Responsive - Iphone Se*/
and (max-device-width : 1081px) 
and (orientation: landscape) {
    .div-innova-techno{
        height:65px;
        top:32vh;
        max-width: 90vw;
        font-size: 3vw;
     }
     .con{
        left:40px;
     }
     .alma{
        font-size: 5vw;
        left:14vw;
     }
}

/* #Dispositivo = Iphone 6/7/8  (hasta el 2023)
#Resolucion = B/w 768px to 1024px */
@media only screen 
and (max-device-width : 414px) 
and (max-device-height : 736px) 
and (-webkit-device-pixel-ratio : 3 ) 
and (orientation : portrait) {
    .div-hand-techno{
        width: 100vw; 
        margin:0px;;
        top:0px;
        bottom:0px;
        left:0px;
        max-height: -webkit-fill-available;
    } 
     .innova-techno{
        left:0px;
     }
     .con{
        left:0px;
     }
     .alma{
        font-size: 40px;
        left:110px;
     }
}
/* iPads (portrait) 
#Dispositivo = Tablets, Ipads (vertical)  (hasta el 2023)
#Resolucion = B/w 768px to 1024px */
@media only screen and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : portrait) {
    .div-innova-techno{
        height:100px;
        top:890px;
        max-width: 90vw;
        font-size: 50px;
     }
     .con{
        left:0px;
     }
     .alma{
        font-size: 95px;
        left:220px;
     }
}
/* 
#Dispositivo = Ipad Mini
#Resolucion = 1024px */
@media only screen 
and (min-device-width : 1024px) 
and (max-device-width : 1024px) 
and (orientation : landscape) {
 
}
/* 
#Dispositivo = Ipad Mini
#Resolucion = 1024px */
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 768px) 
and (orientation : portrait) {
    .div-innova-techno{
        height:100px;
        top:690px;
        max-width: 90vw;
        font-size: 50px;
     }
     .con{
        left:0px;
     }
     .alma{
        font-size: 95px;
        left:210px;
     }
}
@media only screen /* Ipads Pro portrait 2024*/
and (min-device-width : 1025px) 
and (max-device-width : 1500px)
and (orientation: portrait) {
    .div-innova-techno{
        height:100px;
        top:890px;
        max-width: 90vw;
        font-size: 50px;
   
         }
     .con{
        left:0px;
     }
     .alma{
        font-size: 95px;
        left:235px;
     }
}
/* iPads (portrait) 
#Dispositivo = Tablets, Ipads (vertical)  (hasta el 2023)*/
@media only screen /* Ipads landscape*/
and (min-device-width : 1025px) 
and (max-device-width : 1500px)
and (orientation: landscape) {
    .div-innova-techno{
        height:100px;
        top:35vh;
        max-width: 90vw;
        font-size: 50px;
     }
     .con{
        left:0px;
     }
     .alma{
        font-size: 95px;
        left:110px;
     }
}
/* Iphone 16 Iphone 12 pro */
@media only screen 
and (max-device-width: 393px) 
and (max-resolution: 3dppx) 
and (min-width: 200px)
and (-webkit-device-pixel-ratio : 3 ) 
and (orientation: portrait) 
{ 
    .div-innova-techno{
        top:60vh;
     }
    .alma{
        font-size: 45px;
        left:22vw;
        width:200px;
        margin:3px;
     }
}
/* Termina Movil*/