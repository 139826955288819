/* Desktop */
.div-hand-techno{
    position:absolute;
    top:0vh;
    right:0px;
    height: 1044px;
    width:750px;
    max-height: -webkit-fill-available;
}
.hand-techno{
    position:inherit;
    aspect-ratio: 4/6;
    top:0px;
    height: 100%;
}
 /*Comienza Movil*/
@media only screen /* móviles en general portrait*/
and (orientation: portrait) {
    .div-hand-techno{
        top:0vh;
        right:0px;
        width: 400px; 
        height: 550px;
    }
    .hand-techno{
        right:0px;
    }
}
@media only screen /* Glaxy, Iphone Se y responsive*/
and (max-device-width : 375px) 
and (orientation: portrait) {
    .div-hand-techno{
        right:0px;
        width: 350px; 
        height: 400px;
    }
}
@media only screen /* horizontal Responsive - Iphone Se*/
and (max-device-width : 1081px) 
and (orientation: landscape) {
    .div-hand-techno{
        right:0px;
        width: 350px; 
        height: 450px;
       }
}

/* #Dispositivo = Iphone 6/7/8  (hasta el 2023)
#Resolucion = B/w 768px to 1024px */
@media only screen 
and (max-device-width : 414px) 
and (max-device-height : 736px) 
and (-webkit-device-pixel-ratio : 3 ) 
and (orientation : portrait) {
    .div-hand-techno{
        width: 100vw; 
        margin:0px;;
        top:0px;
        bottom:0px;
        left:0px;
        max-height: -webkit-fill-available;
    } 
    .hand-techno{
        position:inherit;
        aspect-ratio: 4/6;
        top:0px;
        height: 100%;
    }
}
/* iPads (portrait) 
#Dispositivo = Tablets, Ipads (vertical)  (hasta el 2023)
#Resolucion = B/w 768px to 1024px */
@media only screen and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : portrait) {
    .div-hand-techno{
        width: 600px; 
        height: 850px;
        margin:auto;
        top:0px;
        bottom:0px;
        left:0px;
        margin:50px;
        padding:30px;
    } 
}
/* 
#Dispositivo = Ipad Mini
#Resolucion = 1024px */
@media only screen 
and (min-device-width : 1024px) 
and (max-device-width : 1024px) 
and (orientation : landscape) {
    .div-hand-techno{
        width: 600px; 
        height: 850px;
    } 
}
/* 
#Dispositivo = Ipad Mini
#Resolucion = 1024px */
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 768px) 
and (orientation : portrait) {
    .div-hand-techno{
        width: 400px; 
        height: 550px;
        margin:auto;
        top:0px;
        bottom:0px;
        left:0px;
        margin:50px;
        padding:30px;
    } 
}
@media only screen /* Ipads Pro portrait 2024*/
and (min-device-width : 1025px) 
and (max-device-width : 1500px)
and (orientation: portrait) {
    .div-hand-techno{
        right:100px;
        width: 700px; 
        height: 900px;
    }
}
/* iPads (portrait) 
#Dispositivo = Tablets, Ipads (vertical)  (hasta el 2023)*/
@media only screen /* Ipads landscape*/
and (min-device-width : 1025px) 
and (max-device-width : 1500px)
and (orientation: landscape) {
    .div-hand-techno{
        right:0px;
        width: 700px; 
        height: 950px;
    }
}
/* Iphone 16 Iphone 12 pro */
@media only screen 
and (max-device-width: 393px) 
and (max-resolution: 3dppx) 
and (min-width: 200px)
and (-webkit-device-pixel-ratio : 3 ) 
and (orientation: portrait) 
{ 
     .div-hand-techno{
        right:0px;
        left:unset;
    }
}
/* Termina Movil*/